/* Body.css */
body {
  scrollbar-color: red yellow;
}

.app-container {
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
}

.settings-container {
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
}

.pincode-container {
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.pincode-container h2 {
  text-align: center;
}

.list-container {
  overflow-y: auto;
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 650px;
  max-height: 800px;
}

.list-content {
  justify-content: center;
  flex-direction: column;
  display: flex;
}

.overlay-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
  color: #f8f8f8;
  background: #415284;
}

.cc_overlay {
  display: flex;
  font-family: var(--system-text);
  max-width: 80%;
}

.cc_icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 30px;
  min-width: 250px;
  height: calc(100% + 30px);
}

.cc_icon object {
  max-width: 200px;
}

.cc_title {
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 20px;
}

.cc_img {
  display: block;
}

.cc_intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cc_description {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  min-width: 290px;
  margin-bottom: 0;
}

.cc_download {
  margin: 10px auto 0;
  font-size: 18px;
}

.cc_version {
  position: absolute;
  right: 20px;
  bottom: 15px;
}

.cc_card {
  display: flex;
  background: #f8f8f8;
  padding: 20px 0px;
}

.card {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background: #415284;
  color: #f8f8f8;
}

.card_content {
  padding: 20px;
  flex: 1;
}

.card object {
  height: 400px;
  object-fit: cover;
}

.grid object {
  width: 100px;
  height: 100px;
  margin-top: 50px;
}

.card h2 {
  font-size: 16px;
  font-weight: 500;
}

.card h4 {
  text-align: left;
  font-size: 12px;
  font-weight: 400;
}

.byod {
  background: #415284;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.byod_content {
  position: relative;
}

.byod object {
  width: 100%;
  object-fit: cover;
}

.byod_description {
  position: absolute;
  top: 10px;
  left: 20px;
  color: #ccc;
}

.byod_description h2 {
  font-size: 16px;
  color: black;
  margin-top: 10px;
}

.byod_description h4 {
  font-size: 12px;
  color: black;
  width: 500px;
  margin-top: 10px;
}

/* Modal.css */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  width: 500px;
}

.back-icon {
  height: 24px;
  width: 24px;
}

.content {
  flex: 1;
  padding: 40px;
  background: #415284;
  overflow-y: auto;
}

.subscribe {
  margin: 20px auto;
}

.content h2,
.content h3 {
  margin-bottom: 20px;
}

.status {
  margin-top: 18px;
  background-color: #415284;
  /* color: white; */
}
