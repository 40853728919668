/* Header.css */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5vw;
  background-color: #ffffff;
}

.header-logo {
  width: 20vw;
  min-width: 200px;
  height: auto;
}

.login-button {
  width: 10vw;
  min-width: 100px;
  height: 49px;
  padding: 10px 20px;
  border: 1px solid #01256b;
  border-radius: 24px;
  background-color: #415284;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.login-button img {
  width: 27px;
  height: 27px;
}
