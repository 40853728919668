/* google login */
.google {
  margin: 15px auto 0;
}

#google-signin {
  display: block;
  min-width: 200px;
  padding: 10px;
  background-color: white;
  color: #3c4043;
  text-decoration: none;
  border: 1px solid #3c4043;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}

.google .x-icon {
  height: 1.5em;
  width: 1.5em;
  top: 0.4em;
  position: relative;
  margin-right: 0.5em;
}

.x-alt {
  color: #777;
}
